import React from 'react'
import './ThreeButtons.css'

function ThreeButtons({ buttons, data, setData, attribute }) {
    const handleButtonClick = (item) => {
        setData(prevData => ({
            ...prevData,
            [attribute]: item
        }));
    };
    return (
        <div>
            <div className='three_buttons'>
                {
                    buttons.map((item, index) => {
                        return (
                            <div
                                className={`three_buttons__button ${data[attribute] === item && "button_active"}`}
                                key={index}
                                onClick={() => handleButtonClick(item)}
                            >
                                {item}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ThreeButtons