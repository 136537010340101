import React from 'react'
import DragAndDrop from './DragAndDrop'
import './MultipleDragDrop.css'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

function MultipleDragDrop({ items, isHasRadio }) {
    return (
        <div className='multiple_drag_drop'>
            {
                items.map((item, index) => {
                    return (
                        <div className='multiple_drag_drop__item' key={index}>
                            <div className='multiple_drag_drop__item__title'>{item}</div>
                            <DragAndDrop />
                            {isHasRadio &&
                                <div style={{display:"flex", alignItems:"center", gap:"0.5rem"}}>
                                    <FormLabel className='onboarding_radio_group__title'>Create Permit:</FormLabel>
                                    <FormControl >
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            className='onboarding_radio_group__items'
                                            row

                                        >

                                            <FormControlLabel
                                                className='onboarding_radio_group__item'
                                                value="Yes"
                                                control={<Radio sx={{ transform: "scale(0.8)", '&.Mui-checked': { color: '#126342' } }} />}
                                                label="Yes"
                                                sx={{fontSize:"0.7rem", color:"var(--new-gray-clr2)"}}
                                            />
                                            <FormControlLabel
                                                className='onboarding_radio_group__item'
                                                value="No"
                                                control={<Radio sx={{ transform: "scale(0.8)", '&.Mui-checked': { color: '#126342' } }} />}
                                                label="No"
                                                sx={{fontSize:"0.7rem", color:"var(--new-gray-clr2)"}}
                                            />

                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}

export default MultipleDragDrop