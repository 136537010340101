import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function FinancialFundPage() {

    const permits1 = [
        "Annual Budget & Financial Forecasts",
        "Bond Issuance History",
        "Grant History / Active Applications",
        "Public Improvement Financing Tools (PID, SID, BID)",
        "TIF District Maps & Policies"
      ]
      
      
      
      


    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Financials & Funding Tools</div>
                        <div className='general_onboarding__section_subtitle'>Please upload any of the following materials you might have:</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1}/>
                    </div>
                </div >
            </div>
        </>
    )
}

export default FinancialFundPage