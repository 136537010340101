import React, { useState } from 'react'
import ButtonCheckBox from '../components/ButtonCheckBox'
import OnboardingTextField from '../components/OnboardingTextField';
import ThreeButtons from '../components/ThreeButtons';

function QuestionnairePage() {

    const [data, setData] = useState({
        dev_area_comments: "",
    });




    return (
        <>
            <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Questionnaire</div>
                </div>
                
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Does your city have a municipal attorney or legal counsel that regularly reviews planning and zoning decisions?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Yes – In-house", "Yes – Contracted", "No"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Is there a formal appeal process for denied permits or zoning actions?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Yes – Staff appeal", "Yes – Planning commission or council", "No – Decisions are final", "Not sure"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Housing & Affordability Incentives</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>What affordability policies are currently active?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Inclusionary Zoning", "Density Bonuses", "ADU incentives", "Tax abatements / Fee waivers", "None"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>What is your city’s position on missing middle housing?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Actively incentivizing it", "Open to it, but case-by-case", "Restrictive (e.g., mostly SFH zones)", "Not sure"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Interagency Coordination</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Do you coordinate planning approvals with any of the following regional entities?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["County government", "MPO / COG", "Utility providers", "School districts", "Housing authorities", "Other"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Are there priority development areas (e.g., TODs, Opportunity Zones, downtown overlays)?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ThreeButtons buttons={["yes","unsure", "no"]} data={data} setData={setData} attribute={"dev_area_comments"}/>
                    </div>
                    {
                        data.dev_area_comments === "yes" &&
                        <div className='general_onboarding__section_sub_inputs'>
                            <OnboardingTextField label={"If yes"} isFullWidth={true} />
                        </div>
                    }
                </div>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Community Feedback & Transparency</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Do you maintain a digital platform where residents can view active projects or submit feedback?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Yes – City-run platform", "Yes – 3rd party vendor (e.g., PublicInput, Bang the Table)", "No"]} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>How do you typically notify the public about planning/zoning changes?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={["Physical postings", "Local newspaper", "Email newsletter / website", "Social media", "Direct mailings"]} isRadio={true}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionnairePage