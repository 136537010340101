import React, { useState } from 'react'
import ButtonCheckBox from '../components/ButtonCheckBox'
import OnboardingTextField from '../components/OnboardingTextField';

function StaffCapacityPage() {


    const headCounts = [
        "Fewer than 50",
        "51-150",
        "151-300",
        "300-1000",
        "Over 1000"
      ]

    const approvalDays = [
        "Less than 30 days",
        "30-60 days",
        "60-90 days",
        "Over 90 days"
      ]
      

    const [data, setData] = useState({
        
    });




    return (
        <>
            <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Staff & Capacity</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Planning Department Headcount</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <OnboardingTextField label={"Number of full-time planners"} isFullWidth={true}/>
                        <OnboardingTextField label={"Number of part-time staff or consultants"} isFullWidth={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Number of development applications received annually (estimate)</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={headCounts} isRadio={true}/>
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>How long is the average timeline for permit approval (non-fast-tracked)?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={approvalDays} isRadio={true}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaffCapacityPage