import { InsertDriveFile } from '@mui/icons-material'
import React from 'react'
import './DragAndDrop.css'

function DragAndDrop() {
  return (
    <div className='drag_and_drop'>
        <InsertDriveFile className='drag_and_drop__file_icon'/>
        <div className='drag_and_drop__text1'>Drag & drop file</div>
        <div className='drag_and_drop__text2'>or <span className='drag_and_drop__browse'>Browse file</span> on your computer</div>

    </div>
  )
}

export default DragAndDrop