import React, { useState } from 'react'
import ButtonCheckBox from '../components/ButtonCheckBox'
import OnboardingTextField from '../components/OnboardingTextField';
import ThreeButtons from '../components/ThreeButtons';

function StrategicOriPage() {


    const headCounts = [
        "Fewer than 50",
        "51-150",
        "151-300",
        "300-1000",
        "Over 1000"
    ]

    const approvalDays = [
        "Less than 30 days",
        "30-60 days",
        "60-90 days",
        "Over 90 days"
    ]


    const [data, setData] = useState({
        active_plan: "",
        priority_areas: "",
        priority_areas_comments: "",
        planing_goals: "",
    });




    return (
        <>
            <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Strategic Orientation</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Does your city have an active Climate Action, Resilience, or Sustainability Plan?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ThreeButtons buttons={["yes", "in development", "no"]} data={data} setData={setData} attribute={"active_plan"} />
                    </div>

                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Are there priority development areas (e.g., TODs, Opportunity Zones, downtown overlays)?</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ThreeButtons buttons={["yes", "no"]} data={data} setData={setData} attribute={"priority_areas"} />
                    </div>
                    {
                        data.priority_areas === "yes" &&
                        <div className='general_onboarding__section_sub_inputs'>
                            <OnboardingTextField label={"If yes"} isFullWidth={true} />
                        </div>
                    }
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>What are your top three planning goals for the next 12–24 months?</div>
                    </div>

                    <div className='general_onboarding__section_sub_inputs'>
                        <OnboardingTextField label={""} isFullWidth={true} multiline={true} rows={3}/>
                    </div>
                </div>

            </div>
        </>
    )
}

export default StrategicOriPage