import React, { useEffect, useState } from 'react'
import './PlugAndPlay.css'
import NavBar from '../../components/navbar/NavBar'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Alert, Snackbar, TextField } from '@mui/material';
function ACBM() {

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        jobtitle: "",
        company: "",
        email: "",
        phone: "",
        point_of_contact__if_applicable_: "",
        message: ""
    });

    const [isMeetingScheduled, setIsMeetingScheduled] = useState(false);



    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        // if (!formData.firstname.trim()) {
        //     setToastStatus('error');
        //     setToastText('Name is required.');
        //     setToastShow(true);
        //     return false;
        // }
        // if (!formData.title.trim()) {
        //     setToastStatus('error');
        //     setToastText('Title is required.');
        //     setToastShow(true);
        //     return false;
        // }
        // if (!formData.name_of_municipality.trim()) {
        //     setToastStatus('error');
        //     setToastText('Municipality name is required.');
        //     setToastShow(true);
        //     return false;
        // }
        if (!formData.email.trim()) {
            setToastStatus('error');
            setToastText('Email is required.');
            setToastShow(true);

            return false;
        }
        // Email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setToastStatus('error');
            setToastText('Enter a valid email address.');
            setToastShow(true);
            return false;
        }
        if (!formData.phone.trim()) {
            setToastStatus('error');
            setToastText('Phone number is required.');
            setToastShow(true);
            return false;
        }
        // if (!isMeetingScheduled) {
        //     setToastStatus('error');
        //     setToastText('Please schedule a meeting.');
        //     setToastShow(true);
        //     return false;

        // }
        return true;
    };


    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        const form_id = "6e8edea8-b9e3-450a-b731-23721e2d4373"
        const portal_id = "45542635"
        const form_url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal_id}/${form_id}`

        const payload = {
            submittedAt: Date.now(),
            fields: [
                {
                    name: "firstname",
                    value: formData.firstname
                },
                {
                    name: "lastname",
                    value: formData.lastname
                },
                {
                    name: "jobtitle",
                    value: formData.jobtitle
                },
                {
                    name: "company",
                    value: formData.company
                },
                {
                    name: "email",
                    value: formData.email
                },
                {
                    name: "phone",
                    value: formData.phone
                },
                {
                    name: "point_of_contact__if_applicable_",
                    value: formData.point_of_contact__if_applicable_
                },
                {
                    name: "message",
                    value: formData.message
                }
            ]
        };

        try {
            const response = await fetch(form_url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                setToastStatus('success');
                setToastText('Form submitted successfully!');
                setToastShow(true);
                window.location.href = '/cpaas/success';
            } else {
                setToastStatus('error');
                setToastText('There was an error submitting the form.');
                setToastShow(true);
            }
        } catch (error) {
            setToastStatus('error');
            setToastText('There was an error submitting the form.');
            setToastShow(true);
        }



    };

    useCalendlyEventListener({
        onEventScheduled: () => {
            setIsMeetingScheduled(true);
        },
    });

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        script.src = "//js.hs-scripts.com/45542635.js";

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Cleanup when the component unmounts
        };
    }, [])


    return (
        <div>
            <NavBar />
            <div className='plug_and_play'>

                <div className='plug_and_play_container'>

                    <div className='plug_and_play_main'>
                        <div className='plug_and_play_left'>
                            <div className='plug_and_play_title'>City Planning as a Service (CPaaS)</div>
                            <div className='plug_and_play_sub_title'>We'd love to hear from you! Please fill out the form and we'll get back to you as soon as possible.</div>

                            <TextField
                                id="outlined-required"
                                label="First Name"
                                name='firstname'
                                value={formData.firstname}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                id="outlined-required"
                                label="Last Name"
                                name='lastname'
                                value={formData.lastname}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                id="outlined-required"
                                label="Title"
                                name='jobtitle'
                                value={formData.jobtitle}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                id="outlined-required"
                                label="Name of Municipality"
                                name='company'
                                value={formData.company}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}

                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}
                                type='email'
                            />
                            <TextField
                                id="outlined-required"
                                label="Phone *"
                                name='phone'
                                value={formData.phone}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}
                                type='number'
                            />
                            <TextField
                                id="outlined-required"
                                label="Point of Contact (if Applicable)"
                                name='point_of_contact__if_applicable_'
                                value={formData.point_of_contact__if_applicable_}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}
                                multiline
                                rows={4}
                            />
                            <TextField
                                id="outlined-required"
                                label="Are you currently using any other software? If so, please list."
                                name='message'
                                value={formData.message}
                                onChange={handleChange}
                                style={{ width: "100%", marginBottom: "16px" }}
                                multiline
                                rows={4}
                            />

                            {/* <FormControl required>
                                <FormLabel id="demo-row-radio-buttons-group-label">Other Point(s) of Contact</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="other_software"
                                    value={additional}
                                    onChange={(e) => setAdditional(e.target.value)}
                                >

                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="No"
                                    />

                                </RadioGroup>
                            </FormControl>
                            <br />
                            {
                                additional && additional === "yes" &&
                                <>
                                    <TextField
                                        id="outlined-required"
                                        label="Name"
                                        name='other_name'
                                        value={formData.other_name}
                                        onChange={handleChange}
                                        style={{ width: "100%", marginBottom: "16px" }}

                                    />
                                    <TextField
                                        id="outlined-required"
                                        label="Title"
                                        name='other_title'
                                        value={formData.other_title}
                                        onChange={handleChange}
                                        style={{ width: "100%", marginBottom: "16px" }}

                                    />
                                    <TextField
                                        id="outlined-required"
                                        label="Email"
                                        name='other_email'
                                        value={formData.other_email}
                                        onChange={handleChange}
                                        style={{ width: "100%", marginBottom: "16px" }}
                                        type='email'
                                    />
                                    <TextField
                                        id="outlined-required"
                                        label="Phone"
                                        name='other_phone'
                                        value={formData.other_phone}
                                        onChange={handleChange}
                                        style={{ width: "100%", marginBottom: "16px" }}
                                        type='number'
                                    />
                                </>
                            }


                            <FormControl required>
                                <FormLabel id="demo-row-radio-buttons-group-label">Are you currently using any other software? *</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="other_software"
                                    value={formData.other_software}
                                    onChange={handleChange}
                                >

                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio sx={{ color: '#126342', '&.Mui-checked': { color: '#126342' } }} />}
                                        label="No"
                                    />

                                </RadioGroup>
                            </FormControl>

                            {
                                formData.other_software === "yes" &&
                                <TextField
                                    id="outlined-required"
                                    label="If so, please list(separate by comma)."
                                    name='other_software_details'
                                    value={formData.other_software_details}
                                    onChange={handleChange}
                                    style={{ width: "100%", marginBottom: "16px" }}
                                />
                            } */}


                        </div>
                        <div className='plug_and_play_right'>
                            <div className='plug_and_play_title' style={{ fontSize: "1.5rem", fontWeight: "600" }}>Schedule</div>
                            <div style={{ marginBottom: "1rem" }}>Choose a time to connect with the founder of IQLand, Phillip Boykin</div>
                            <InlineWidget
                                // url="https://calendly.com/asamhussainkmm/30min"
                                url="https://calendly.com/phillip-boykin-iqland/30min"
                                styles={{
                                    minHeight: "300px",
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                    marginBottom: "1rem",
                                    padding: "0 12px",
                                    scrollbarWidth: "thin",
                                    scrollbarColor: "#126342 #f0f0f0"
                                }}

                                prefill={{
                                    name: formData.firstname,
                                    email: formData.email
                                }}

                                pageSettings={{
                                    primaryColor: "126342 !important",
                                    textColor: "126342",
                                    backgroundColor: "ffffff",

                                }}
                            />
                        </div>
                    </div>

                    <div className='plug_and_play_submit' onClick={handleSubmit}>Submit</div>
                </div>

            </div>

            <Snackbar
                open={toastShow}
                autoHideDuration={3000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>

        </div >
    )
}

export default ACBM