import React, { useEffect, useState } from 'react'
import './ButtonCheckBox.css'
import { TextField } from '@mui/material';
import OnboardingTextField from './OnboardingTextField';

function ButtonCheckBox({ items, isRadio }) {

    const [selectedItems, setSelectedItem] = useState([]);
    const handleItemClick = (item) => {
        if (isRadio) {
            setSelectedItem([item]);
        } else {
            if (selectedItems.includes(item)) {
                setSelectedItem(selectedItems.filter((selectedItem) => selectedItem !== item));
            } else {
                setSelectedItem([...selectedItems, item]);
            }
        }
    }


    useEffect(() => {
        if (selectedItems === items) {

        }
    }, [selectedItems])


    return (
        <>
            <div className='button_checkbox'>

                {
                    !isRadio && <div
                        className={`button_checkbox__item ${selectedItems.length === items.length && "item_active"}`}
                        onClick={() => {
                            if (selectedItems === items) {
                                setSelectedItem([]);
                            } else {
                                setSelectedItem(items);
                            }
                        }
                        }
                    >
                        Select All
                    </div>
                }
                {
                    items.map((item, index) => {
                        return (
                            <div
                                className={`button_checkbox__item ${selectedItems.includes(item) && "item_active"}`}
                                key={index}
                                onClick={() => handleItemClick(item)}
                            >

                                {item}
                            </div>
                        )
                    })
                }
                {
                    selectedItems.includes("Other") &&
                    <OnboardingTextField label={"Please specify"} isFullWidth={true}/>
                }
            </div>

        </>
    )
}

export default ButtonCheckBox