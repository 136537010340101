import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function PermitsPage4() {

    const permits1 = [
        "Special Event",
        "Stormwater",
        "Street Opening",
        "Structural Retrofit",
        "Swimming Pool",
        "Telecommunications",
        "Temporary Structure",
        "Temporary Use",
        "Traffic Control",
        "Tree Removal",

    ]




    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Permit Documentation Upload</div>
                        <div className='general_onboarding__section_subtitle'>Upload any existing permits. If none exist, indicate if you'd like us to create new ones for your project.</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1} isHasRadio={true} />
                    </div>
                </div >
            </div>
        </>
    )
}

export default PermitsPage4