import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function CommunityToolPage() {

    const permits1 = [
        "Affordable Housing Incentive Programs",
        "Equity or Justice40 Frameworks (if applicable)",
        "Historic Preservation Guidelines",
        "Neighborhood / District-level Plans",
        "Public Engagement Reports or Survey Results"
      ]
      
      
      


    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Community Engagement Tools & Policy</div>
                        <div className='general_onboarding__section_subtitle'>Please upload any of the following materials you might have:</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1}/>
                    </div>
                </div >
            </div>
        </>
    )
}

export default CommunityToolPage