import React, { useEffect, useState } from 'react'
import ButtonCheckBox from '../components/ButtonCheckBox'
import ThreeButtons from '../components/ThreeButtons';

function DigitalToolPage() {

    const permittings = [
        "Accela",
        "OpenGov",
        "CityWorks",
        "Tyler Munis",
        "Other"
    ]

    const planReviewMethods = [
        "Bluebeam",
        "ePlans",
        "ProjectDox",
        "Adobe PDF Workflow",
        "Other"
    ];

    const gisMappingTools = [
        "ESRI ArcGIS",
        "MapGeo",
        "Google Maps API",
        "Other"
    ];

    const codeEnforcementTools = [
        "iWorQ",
        "Municity",
        "CitizenServe",
        "Other"
    ];

    const grantManagementTools = [
        "eCivis",
        "Fluxx",
        "GovGrants",
        "None",
        "Other"
    ];

    const communityEngagementTools = [
        "Bang the Table",
        "Neighborland",
        "PublicInput",
        "Facebook Groups",
        "Other"
    ];


    const [data, setData] = useState({
        permitting: [],
        planReview: [],
        gisMapping: [],
        codeEnforcement: [],
        grantManagement: [],
        communityEngagement: [],
        digitalPermitPortal: "",
        epaymentWorkflow: ""
    });


    useEffect(() => {
        if(data.digitalPermitPortal !== "yes"){
            setData(prevData => ({
                ...prevData,
                epaymentWorkflow: ""
            }))
        }
    }, [data.digitalPermitPortal])


    return (
        <>
            <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Digital Tools & Technology Stack</div>
                    <div className='general_onboarding__section_subtitle'>What software platforms does your city currently use for the following?</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>Permitting</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={permittings} />
                    </div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>Plan Review</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={planReviewMethods} />
                    </div>

                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>GIS Mapping</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={gisMappingTools} />
                    </div>

                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>Code Enforcement</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={codeEnforcementTools} />
                    </div>

                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>Grant Management</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={grantManagementTools} />
                    </div>

                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div style={{ color: "#777", fontWeight: "600" }}>Community Engagement</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ButtonCheckBox items={communityEngagementTools} />
                    </div>

                </div>

                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>

                        <div className='general_onboarding__section_subtitle'>Do you currently have a digital permitting portal?</div>

                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <ThreeButtons buttons={["yes", "in progress", "no"]} data={data} setData={setData} attribute={"digitalPermitPortal"}/>
                    </div>

                </div>
                {
                    data.digitalPermitPortal.toLowerCase() === "yes"
                    && <div className='general_onboarding__section_inputs'>
                        <div className='general_onboarding__section_sub_inputs'>

                            <div className='general_onboarding__section_subtitle'>If yes, does it include e-payments and digital signature workflows?</div>

                        </div>
                        <div className='general_onboarding__section_sub_inputs'>
                            <ThreeButtons buttons={["yes", "partially", "no"]} data={data} setData={setData} attribute={"epaymentWorkflow"}/>
                        </div>

                    </div>
                }
            </div>
        </>
    )
}

export default DigitalToolPage