import React, { useState } from 'react'
import Footer from '../../components/footer/Footer'
import './GeneralOnboarding.css'
import ContactPage from './pages/ContactPage'
import { Circle } from '@mui/icons-material'
import DigitalToolPage from './pages/DigitalToolPage'
import StaffCapacityPage from './pages/StaffCapacityPage'
import StrategicOriPage from './pages/StrategicOriPage'
import QuestionnairePage from './pages/QuestionnairePage'
import MapPage from './pages/MapPage'
import PermitsPage1 from './pages/PermitsPage1'
import PermitsPage2 from './pages/PermitsPage2'
import PermitsPage3 from './pages/PermitsPage3'
import PermitsPage4 from './pages/PermitsPage4'
import PermitsPage5 from './pages/PermitsPage5'
import DeveRelatedPage from './pages/DevRelatedPage'
import SustainabilityPage from './pages/SustainabilityPage'
import CommunityToolPage from './pages/CommunityToolPage'
import PipelinePage from './pages/PipelinePage'
import FinancialFundPage from './pages/FinancialFundPage'
import OrgDetailsPage from './pages/OrgDetailsPage'
import AdditionalPage from './pages/AdditionalPage'
import PropertiesPage from './pages/PropertiesPage'
import NavBarMuni from '../../components/navbar/NavBarMuni'

function GeneralOnboarding() {

    const [formData, setFormData] = useState({
        primary_name: '',
        primary_email: '',
        primary_phone: '',
        primary_mobile: '',
        primary_fax: '',
        technical_name: '',
        technical_email: '',
        technical_phone: '',
        technical_mobile: '',
        technical_fax: '',
        bill_name: '',
        bill_email: '',
        bill_phone: '',
        bill_mobile: '',
        bill_fax: '',
        logo_artwork: '',
        master_plan: 'Master Plan',
        master_plan_attachment: '',
        zoning_code: 'NA',
        zoning_code_attachment: '',
        zoning_map: 'NA',
        zoning_map_attachment: '',
        parcel_polygons: 'NA',
        parcel_polygons_attachment: '',
        overlay_maps: 'NA',
        overlay_maps_attachment: '',
        all_properties: '',
        type_of_asset: 'Vacant Lot',

        permit_types: [
            { building: "" },
            { demolition: "" },
            { foundation: "" },
            { temporary_structure: "" },
            { renovation: "" },
            { tenant_improvement: "" },
            { historic_preservation: "" },
            { electrical: "" },
            { plumbing: "" },
            { mechanical: "" },
            { gas_line: "" },
            { energy_efficiency: "" },
            { fire_safety: "" },
            { structural_retrofit: "" },
            { ada_compliancy: "" },
            { grading: "" },
            { excavation: "" },
            { erosion_control: "" },
            { drainage: "" },
            { landscaping: "" },
            { temporary_use: "" },
            { special_event: "" },
            { blasting_permit: "" },
            { crane_permit: "" },
            { environmental_impact_permit: "" },
            { stormwater: "" },
            { tree_removal: "" },
            { wetlands: "" },
            { sign: "" },
            { facade: "" },
            { zoning_compliance: "" },
            { home_occupation: "" },
            { conditional_use: "" },
            { variance: "" },
            { encroachment: "" },
            { curb_cut: "" },
            { street_opening: "" },
            { sidewalk: "" },
            { traffic_control: "" },
            { swimming_pool: "" },
            { solar_panel: "" },
            { telecommunications: "" },
            { food_truck_pop_up: "" },
            { utility_connection: "" }
        ]


    });





    const [page, setPage] = useState(1);

    const handlePageNextClick = () => {
        if(page === 18){
            return
        }
        setPage(page + 1);
    }
    const handlePagePreviousClick = () => {
        setPage(page - 1);
    }


    return (
        <div className='general_onboarding'>
            <NavBarMuni/>

            <div className='general_onboarding__container'>
                <div className='general_onboarding__titles'>
                    <div className='general_onboarding__title'>City Planning as a Service (CPaaS)</div>
                    <div className='general_onboarding__subtitle'>General Onboarding</div>
                </div>
                <div className='general_onboarding__form'>
                    {page === 1 && <ContactPage />}
                    {page === 2 && <DigitalToolPage />}
                    {page === 3 && <StaffCapacityPage/>}
                    {page === 4 && <StrategicOriPage/>}
                    {page === 5 && <QuestionnairePage/>}
                    {page === 6 && <MapPage/>}
                    {page === 7 && <PermitsPage1/>}
                    {page === 8 && <PermitsPage2/>}
                    {page === 9 && <PermitsPage3/>}
                    {page === 10 && <PermitsPage4/>}
                    {page === 11 && <PermitsPage5/>}
                    {page === 12 && <PropertiesPage/>}
                    {page === 13 && <DeveRelatedPage/>}
                    {page === 14 && <SustainabilityPage/>}
                    {page === 15 && <CommunityToolPage/>}
                    {page === 16 && <PipelinePage/>}
                    {page === 17 && <FinancialFundPage/>}
                    {page === 18 && <OrgDetailsPage/>}
                    {page === 19 && <AdditionalPage/>}
                    <div className='general_onboarding__navigation'>
                        {
                            page > 1
                                ? <div className='processcard_mou__button' onClick={handlePagePreviousClick}>Previous</div>
                                : <div className='processcard_mou__button' style={{ opacity: "0", cursor: "default" }}>Previous</div>
                        }
                        <div className='general_onboarding__dots'>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((dot, index) => {
                                    return (
                                        <Circle
                                            key={index}
                                            className={`general_onboarding__dot ${page === dot && "active_dot"}`}
                                            sx={{ fontSize: "0.5rem" }}
                                            onClick={() => setPage(dot)}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className='processcard_mou__button' onClick={handlePageNextClick}>{page === 18 ? "Finish" : "Next"}</div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default GeneralOnboarding