import React from 'react'
import OnboardingRadioGroup from '../components/OnboardingRadioGroup'
import OnboardingSelect from '../components/OnboardingSelect'
import MultipleDragDrop from '../components/MultipleDragDrop'

function MapPage() {

    const items1 = [
        "Online",
        "PDF",
        "Other",
        "N/A"
    ]

    const items2 = [
        "Vacant Lot",
        "Occupied building",
        "Unoccupied building",
        "Parking Lot",
        "Park/Trails",
        "Cemetery",
    ]

    const maps = [
        "Sewer & Stormwater System Maps",
        "Water Distribution System",
        "Watershed and Drainage Plans",
        "Topographical / Elevation Maps",
        "Gas",
        "Electric",
        "Fiber Optics"
      ]
      

    return (
        <>
            <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                <div className='general_onboarding__section_title'>Master Plan</div>
                <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                    <div className='general_onboarding__section_subtitle'>Please provide access to your municipal master plan if available.</div>
                    <OnboardingRadioGroup items={items1} />
                </div>
            </div>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                    <div className='general_onboarding__section_title'>Zoning Code</div>
                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_subtitle'>Where can we find your zoning code?</div>
                        <OnboardingRadioGroup items={items1} />
                    </div>
                </div >
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                    <div className='general_onboarding__section_title'>Zoning Map</div>
                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_subtitle'>Where can we find your zoning maps?</div>
                        <OnboardingRadioGroup items={items1} />
                    </div>
                </div >
            </div>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                    <div className='general_onboarding__section_title'>Parcel Poygons</div>
                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_subtitle'>Where are your municipal parcel polygons located?</div>
                        <OnboardingRadioGroup items={items1} />
                    </div>
                </div >
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                    <div className='general_onboarding__section_title'>Overlay maps (If applicable)</div>
                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_subtitle'>Do you have any overlay districts? If so how can we access your maps?</div>
                        <OnboardingSelect items={items2} />
                    </div>
                </div >


            </div>

            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>
                    <div className='general_onboarding__section_title'>Additional Maps (if applicable)</div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <div className='general_onboarding__section_subtitle'>Upload all maps being implemented by the city.</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={maps}/>
                    </div>
                </div >
            </div>


        </>
    )
}

export default MapPage