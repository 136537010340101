import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function PipelinePage() {

    const permits1 = [
        "Capital Improvement Plan (CIP)",
        "Development Fee Schedule",
        "List of Active or Pending Developments",
        "Permit Data Export (last 3 years)",
        "Recent Variance / Conditional Use Permit Approvals"
      ]
      
      
      


    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Development Activity & Pipeline</div>
                        <div className='general_onboarding__section_subtitle'>Please upload any of the following materials you might have:</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1}/>
                    </div>
                </div >
            </div>
        </>
    )
}

export default PipelinePage