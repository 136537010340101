import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'

function OnboardingSelect({items, label}) {

    const [formData, setFormData] = useState({
        type_of_asset: items[0],
    })
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <FormControl sx={{ flexShrink: 0, minWidth: "min(20rem, 100%)" }}>
            
            <Select
                sx={{
                    height: "3rem",
                    paddingTop:"0.5rem",
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--primary-clr)',
                    },
                }}
                

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.type_of_asset}
                label=""
                name='type_of_asset'
                onChange={handleChange}
            >
                {
                    items.map((value, index) => (
                        <MenuItem key={index} value={value}>{value}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default OnboardingSelect