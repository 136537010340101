import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function OrgDetailsPage() {

    const permits1 = [
        "Approval Timelines & Workflow Charts",
        "Digital Permitting Software Currently in Use (if any)",
        "Planning Board / Council Meeting Schedule"
      ]
      
    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Organizational Details</div>
                        <div className='general_onboarding__section_subtitle'>Please upload any of the following materials you might have:</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1}/>
                    </div>
                </div >
            </div>
        </>
    )
}

export default OrgDetailsPage