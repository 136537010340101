import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function PermitsPage2() {

    const permits1 = [
        "Energy Efficiency",
        "Environmental Impact Permit",
        "Erosion Control",
        "Excavation",
        "Facade",
        "Fire Safety",
        "Food Truck / Pop-up",
        "Foundation",
        "Gas Line",
        "Grading",
    ]

    //   [
    //     "ADA Compliancy",
    //     "Blasting Permit",
    //     "Building",
    //     "Conditional Use",
    //     "Crane Permit",
    //     "Curb Cut",
    //     "Demolition",
    //     "Drainage",
    //     "Electrical",
    //     "Encroachment",
    //     "Energy Efficiency",
    //     "Environmental Impact Permit",
    //     "Erosion Control",
    //     "Excavation",
    //     "Facade",
    //     "Fire Safety",
    //     "Food Truck / Pop-up",
    //     "Foundation",
    //     "Gas Line",
    //     "Grading",
    //     "Historic Preservation",
    //     "Home Occupation",
    //     "Landscaping",
    //     "Mechanical",
    //     "Plumbing",
    //     "Renovation",
    //     "Sidewalk",
    //     "Sign",
    //     "Solar Panel",
    //     "Special Event",
    //     "Stormwater",
    //     "Street Opening",
    //     "Structural Retrofit",
    //     "Swimming Pool",
    //     "Telecommunications",
    //     "Temporary Structure",
    //     "Temporary Use",
    //     "Traffic Control",
    //     "Tree Removal",
    //     "Utility Connection",
    //     "Variance",
    //     "Wetlands",
    //     "Zoning Compliance"
    //   ]




    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Permit Documentation Upload</div>
                        <div className='general_onboarding__section_subtitle'>Upload any existing permits. If none exist, indicate if you'd like us to create new ones for your project.</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1} isHasRadio={true} />
                    </div>
                </div >
            </div>
        </>
    )
}

export default PermitsPage2