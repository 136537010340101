import React, { useState } from 'react'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import UploadButton from '../components/UploadButton'
import { permitTypes } from '../data/permitTypes'
import DragAndDrop from '../components/DragAndDrop';
import ButtonCheckBox from '../components/ButtonCheckBox';

function ContactPage() {

    const [formData, setFormData] = useState({
        primary_name: '',
        primary_email: '',
        primary_phone: '',
        primary_mobile: '',
        primary_fax: '',
        technical_name: '',
        technical_email: '',
        technical_phone: '',
        technical_mobile: '',
        technical_fax: '',
        bill_name: '',
        bill_email: '',
        bill_phone: '',
        bill_mobile: '',
        bill_fax: '',
        logo_artwork: '',
        master_plan: 'Master Plan',
        master_plan_attachment: '',
        zoning_code: 'NA',
        zoning_code_attachment: '',
        zoning_map: 'NA',
        zoning_map_attachment: '',
        parcel_polygons: 'NA',
        parcel_polygons_attachment: '',
        overlay_maps: 'NA',
        overlay_maps_attachment: '',
        all_properties: '',
        type_of_asset: 'Vacant Lot',

        permit_types: [
            { building: "" },
            { demolition: "" },
            { foundation: "" },
            { temporary_structure: "" },
            { renovation: "" },
            { tenant_improvement: "" },
            { historic_preservation: "" },
            { electrical: "" },
            { plumbing: "" },
            { mechanical: "" },
            { gas_line: "" },
            { energy_efficiency: "" },
            { fire_safety: "" },
            { structural_retrofit: "" },
            { ada_compliancy: "" },
            { grading: "" },
            { excavation: "" },
            { erosion_control: "" },
            { drainage: "" },
            { landscaping: "" },
            { temporary_use: "" },
            { special_event: "" },
            { blasting_permit: "" },
            { crane_permit: "" },
            { environmental_impact_permit: "" },
            { stormwater: "" },
            { tree_removal: "" },
            { wetlands: "" },
            { sign: "" },
            { facade: "" },
            { zoning_compliance: "" },
            { home_occupation: "" },
            { conditional_use: "" },
            { variance: "" },
            { encroachment: "" },
            { curb_cut: "" },
            { street_opening: "" },
            { sidewalk: "" },
            { traffic_control: "" },
            { swimming_pool: "" },
            { solar_panel: "" },
            { telecommunications: "" },
            { food_truck_pop_up: "" },
            { utility_connection: "" }
        ]


    });


    const assetTypes = [
        "Vacant Lot",
        "Occupied building",
        "Unoccupied building",
        "Parking Lot",
        "Park/Trails",
        "Cemetery",
    ]

    const trainingFormates = [
        "Live Zoom workshop",
        "Self-paced videos",
        "One-on-one walkthroughs",
        "Written documentation",
    ]

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    return (
        <>
            <div className='general_onboarding__section'>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Primary Contact</div>
                    <div className='general_onboarding__section_subtitle'>Who is the primary contact person for this project?</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className='general_onboarding__section_input'
                            name='primary_name'
                            value={formData.primary_name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            type='email'
                            varient="outlined"
                            className='general_onboarding__section_input'
                            name='primary_email'
                            value={formData.primary_email}
                            onChange={handleChange}
                        />

                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='primary_phone'
                            value={formData.primary_phone}
                            onChange={handleChange}

                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Mobile"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='primary_mobile'
                            value={formData.primary_mobile}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Fax"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='primary_fax'
                            value={formData.primary_fax}
                            onChange={handleChange}
                        />

                    </div>
                </div>
            </div>

            <div className='general_onboarding__section'>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Technical Contact</div>
                    <div className='general_onboarding__section_subtitle'>Who is the primary technical contact person for this project?</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className='general_onboarding__section_input'
                            name='technical_name'
                            value={formData.technical_name}
                            onChange={handleChange}

                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            type='email'
                            varient="outlined"
                            className='general_onboarding__section_input'
                            name='technical_email'
                            value={formData.technical_email}
                            onChange={handleChange}
                        />

                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='technical_phone'
                            value={formData.technical_phone}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Mobile"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='technical_mobile'
                            value={formData.technical_mobile}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Fax"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='technical_fax'
                            value={formData.technical_fax}
                            onChange={handleChange}
                        />

                    </div>
                </div>
            </div>
            <div className='general_onboarding__section'>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Billing & Accounts Contact</div>
                    <div className='general_onboarding__section_subtitle'>Who is the primary contact person for billing and accounts?</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className='general_onboarding__section_input'
                            name='bill_name'
                            value={formData.bill_name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            type='email'
                            varient="outlined"
                            className='general_onboarding__section_input'
                            name='bill_email'
                            value={formData.bill_email}
                            onChange={handleChange}
                        />

                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_phone'
                            value={formData.bill_phone}
                            onChange={handleChange}

                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Mobile"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_mobile'
                            value={formData.bill_mobile}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Fax"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_fax'
                            value={formData.bill_fax}
                            onChange={handleChange}
                        />

                    </div>
                </div>
            </div>
            <div className='general_onboarding__section'>
                <div className='general_onboarding__section_header'>
                    <div className='general_onboarding__section_title'>Legal Contact</div>
                    <div className='general_onboarding__section_subtitle'>Who is the legal contact person?</div>
                </div>
                <div className='general_onboarding__section_inputs'>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            className='general_onboarding__section_input'
                            name='bill_name'
                            value={formData.bill_name}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Email"
                            type='email'
                            varient="outlined"
                            className='general_onboarding__section_input'
                            name='bill_email'
                            value={formData.bill_email}
                            onChange={handleChange}
                        />

                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_phone'
                            value={formData.bill_phone}
                            onChange={handleChange}

                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Mobile"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_mobile'
                            value={formData.bill_mobile}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Fax"
                            varient="outlined"
                            type='number'
                            className='general_onboarding__section_input'
                            name='bill_fax'
                            value={formData.bill_fax}
                            onChange={handleChange}
                        />

                    </div>
                </div>
            </div>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section'>
                    <div className='general_onboarding__section_header'>
                        <div className='general_onboarding__section_title'>Training Format</div>
                        <div className='general_onboarding__section_subtitle'>Preferred onboarding and training format</div>
                    </div>
                    <div className='general_onboarding__section_inputs'>
                        <div className='general_onboarding__section_sub_inputs'>
                            <ButtonCheckBox items={trainingFormates} isRadio={true} />
                        </div>

                    </div>
                </div>
            </div>

            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section'>
                    <div className='general_onboarding__section_header'>
                        <div className='general_onboarding__section_title'>Logo Artwork</div>
                        <div className='general_onboarding__section_subtitle'>Upload your logo If available</div>
                    </div>
                    <div className='general_onboarding__section_inputs'>
                        <div className='general_onboarding__section_sub_inputs'>
                            <DragAndDrop />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default ContactPage