import { TextField } from '@mui/material'
import React from 'react'

function OnboardingTextField({label, value, isFullWidth, multiline, rows}) {
    return (
        <TextField
            value={value}
            multiline={multiline}
            rows={rows}
            fullWidth={isFullWidth}
            id="outlined-multiline-static"
            label={label}
            sx={{
                "& .MuiInputBase-root": multiline ? {height: "auto", padding: "auto"} : { height: "2.5rem", padding: "0" }, // Controls outer container
                "& .MuiInputBase-input": { padding: "5px" }, // Reduces inner padding
                "& .MuiInputLabel-root": { top: "-0.45rem" }, // Moves label down
                "& .MuiInputLabel-root.MuiInputLabel-shrink": { top: "0" }
            }}
        />
    )
}

export default OnboardingTextField