import React from 'react'
import MultipleDragDrop from '../components/MultipleDragDrop'

function SustainabilityPage() {

    const permits1 = [
        "Climate Action Plan or Sustainability Plan",
        "Green Infrastructure Plan",
        "Heat Island Maps / Resilience Assessments",
        "Solid Waste and Recycling Plans",
        "Stormwater Management Plans",
        "Tree Canopy or Urban Forest Maps"
      ]
      
      


    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Sustainability & Environmental Planning</div>
                        <div className='general_onboarding__section_subtitle'>Please upload any of the following materials you might have:</div>
                    </div>
                    <div className='general_onboarding__section_sub_inputs'>
                        <MultipleDragDrop items={permits1}/>
                    </div>
                </div >
            </div>
        </>
    )
}

export default SustainabilityPage