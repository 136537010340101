import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavBar.css';
import { useContext } from 'react';
import { AuthContext } from '../auth/AuthenticationContext';
import { useNavigate } from 'react-router-dom';
import { LoadingSkeletonText } from '../skeleton_loading/SkeletonLoading';

export default function NavBarMuni({ handleVisiblity }) {
    const navigate = useNavigate();
    const { user, logOut, loading } = useContext(AuthContext);

    const handleLogout = async () => {
        try {
            await logOut();
        } catch (e) {
            // console.log("from logout", e);
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="custom-nav-bg">
            <Container>
                <Navbar.Brand href="/" className='me-5'>
                    <img
                        alt=""
                        src={process.env.PUBLIC_URL + "/images/logo.png"}
                        // width="100"
                        height="24"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link href='/' className='me-2'>Home</Nav.Link>
                        <NavDropdown title="Products" id="collapsible-nav-dropdown" className='me-2'>
                            <NavDropdown.Item onClick={handleVisiblity} href="/products/copilot">Copilot</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="/products/dashboard">SmartBuild</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="/products/appraiser">Appraisal IQ</NavDropdown.Item>
                        </NavDropdown> */}
                        {/* <NavDropdown title="Features" id="collapsible-nav-dropdown" className='me-2'>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Plan Matchmaker</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Cost Estimator</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Value Predictor</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Equity Calculator</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Zoning Analyzer</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Mapping</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Plan Analyzer</NavDropdown.Item>
                        </NavDropdown> */}
                        {/* <NavDropdown title="Solutions" id="collapsible-nav-dropdown" className='me-2'> */}
                            {/* <NavDropdown.Item onClick={handleVisiblity} href="/solutions/developers">Real Estate Developers</NavDropdown.Item> */}
                            {/* <NavDropdown.Item onClick={handleVisiblity} href="/solutions/agents">Real Estate Agents</NavDropdown.Item> */}
                            {/* <NavDropdown.Item onClick={handleVisiblity} href="/solutions/architects">Architects</NavDropdown.Item> */}
                            {/* <NavDropdown.Item disabled onClick={handleVisiblity} href="#">Governments</NavDropdown.Item> */}
                        {/* </NavDropdown> */}
                        {/* <NavDropdown title="Resources" id="collapsible-nav-dropdown" className='me-2'>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">FAQ</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Terms and Conditions</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Privacy Policy</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleVisiblity} href="#join-waitlist">Enterprise Solutions</NavDropdown.Item>
                        </NavDropdown> */}
                        {/* <Nav.Link href='/pricing'>Pricing</Nav.Link> */}
                    </Nav>
                    <Nav className='nav-buttons'>
                        {
                            loading ? (
                                <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
                            ) : (
                                user ? (
                                    <Nav className='nav-buttons'>
                                        {/* <div onClick={() => navigate("/copilot")} variant="success" className='lan_primary_button me-2'>Copilot</div> */}
                                        <div onClick={handleLogout} variant="success" style={{maxWidth:"9.8rem"}} className='lan_secondary_button me-2'>Logout</div>
                                    </Nav>
                                ) : (
                                    <Nav className='nav-buttons'>
                                        <div className="lan_secondary_button me-2" style={{maxWidth:"9.8rem"}} onClick={() => navigate("/login")} variant="success">Log In</div>
                                        {/* <div className="lan_primary_button me-2" onClick={() => navigate("/register")} variant="success">Sign Up</div> */}
                                    </Nav>
                                )
                            )
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
