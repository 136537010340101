import React, { useState } from 'react'
import OnboardingTextField from '../components/OnboardingTextField'
import OnboardingSelect from '../components/OnboardingSelect'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function PropertiesPage() {

    const type_of_asset = [
        "Cemetery",
        "Occupied Building",
        "Parking Lot",
        "Park/Trails",
        "Unoccupied Building",
        "Vacant Lot"
    ]

    const [items, setItems] = useState([1]);

    return (
        <>
            <div className='general_onboarding__sections'>
                <div className='general_onboarding__section' style={{ gap: "2rem" }}>

                    <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                        <div className='general_onboarding__section_title'>Properties</div>
                        <div className='general_onboarding__section_subtitle'>List all properties owned by the City of Tuskegee</div>
                    </div>
                    {
                        items.map((item, index) => {
                            return (
                                <div key={index} className='general_onboarding__section_sub_inputs' style={{ alignItems: "center" }}>
                                    <OnboardingTextField label="address/parcel ID" isFullWidth={true} />
                                    <FormControl sx={{ flexShrink: 0, flexGrow: "1", flex: "1", minWidth: "14rem", width: "30%" }} size='small'>
                                        <InputLabel
                                            sx={{
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'var(--primary-clr)',
                                                },
                                            }}
                                            id="demo-simple-select-label">Type of asset</InputLabel>
                                        <Select
                                            sx={{
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'var(--primary-clr)',
                                                },
                                            }}

                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Type of asset"
                                            defaultValue={" "}
                                            displayEmpty
                                        >
                                            <MenuItem value=" " disabled>Select an option</MenuItem>
                                            {
                                                type_of_asset.map((value, index) => (
                                                    <MenuItem key={index} value={value}>{value}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        })
                    }
                </div >

            </div>
            <div className='general_onboarding__section_sub_inputs' style={{ flexDirection: "column" }}>
                <div className='processcard_mou__button' onClick={() => setItems([...items, 1])}>+ Add new parcel</div>
            </div>
        </>
    )
}

export default PropertiesPage