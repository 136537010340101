import { AttachFile } from "@mui/icons-material"
import './UploadButton.css'

function UploadButton({ handleClick, isActive, title }) {



    return (
        <div className="upload_button__container">
            {title && <div className="upload_button_title">{title}</div>}
            <div
                className={isActive ? "upload_button" : "upload_button upload_button__inactive"}
                onClick={isActive ? handleClick: null}>
                <AttachFile /> Attachment
            </div>
        </div>
    )
}

export default UploadButton