import React from 'react'
import NavBar from '../../components/navbar/NavBar'

function SuccessSubmitACBM() {
  return (
    <div>
        <NavBar/>
        <div className='pp_thank_you_container'>
            <div className='pp_thank_you_emoji' data-aos="zoom-in" data-aos-duration="500"><img src="/iq_ic.png" alt='IQLand'/></div>
            <h1 className='pp_thank_you_title'>Thank you for your interest in IQLand.</h1>
            <p className='pp_thank_you_para'>Someone will be in contact with you shortly.</p>
            <div className='pp_thank_you_button' onClick={() => window.location.href = '/'}>Home</div>
        </div>
    </div>
  )
}

export default SuccessSubmitACBM