import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import './OnboardingRadioGroup.css'
import OnboardingTextField from './OnboardingTextField';
import DragAndDrop from './DragAndDrop';

function OnboardingRadioGroup({ items, label }) {
    const [value, setValue] = React.useState('');
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <>
            <FormControl className='onboarding_radio_group'>
                <FormLabel>{label}</FormLabel>
                <RadioGroup className='onboarding_radio_group__items' row>
                    {
                        items.map((item, index) => {
                            return (
                                <FormControlLabel
                                    onChange={handleChange}
                                    className='onboarding_radio_group__item'
                                    key={index}
                                    value={item}
                                    control={<Radio sx={{ '&.Mui-checked': { color: '#126342' }}}/>}
                                    label={item}
                                />
                            )
                        })
                    }
                </RadioGroup>
            </FormControl>
            {
                value.toLowerCase() === 'other' &&
                <OnboardingTextField label={"Please specify"} isFullWidth={true}/>
            }
            {
                value.toLowerCase() === "pdf" &&
                <DragAndDrop/>
            }
        </>
    )
}

export default OnboardingRadioGroup