import React from 'react'
import ProcessCard from './components/ProcessCard'
import "./OnBoardingMou.css"
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'

function OnBoardingMou() {

  const navigate = useNavigate();

  const mouList = [
    {
      "title": "General Onboarding Process",
      "processes": [
        "Contract Execution",
        "Complete Onboarding Card",
        "Implementation",
        "Product integration"
      ],
      "onClick": () => navigate("/municipal/onboarding/general"),
      "isActive": true
    },
    {
      "title": "City Planning as a Service (CPAAS)",
      "processes": [
        "Verify Onboarding",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/cpaas"),
      "isActive": false
    },
    {
      "title": "MasterPlan (Dynamic)",
      "processes": [
        "Verify Onboarding",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/masterplan-dynamic"),
      "isActive": false
    },
    {
      "title": "MasterPlan (Static)",
      "processes": [
        "Verify Onboarding",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/masterplan-static"),
      "isActive": false
    },
    {
      "title": "GROW",
      "processes": [
        "Verify Onboarding",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/grow"),
      "isActive": false
    },
    {
      "title": "GovBot",
      "processes": [
        "Verify Onboarding",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/govbot"),
      "isActive": false
    },
    {
      "title": "MuniBot (Entitlements Only)",
      "processes": [
        "Verify Onboarding (A la carte only)",
        "Implementation",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/munibot-entitlements"),
      "isActive": false
    },
    {
      "title": "APP",
      "processes": [
        "Verify Onboarding",
        "APP Operations",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/app"),
      "isActive": false
    },
    {
      "title": "FAST",
      "processes": [
        "Verify Onboarding",
        "FAST Operations Guide",
        "Transition into Customer Success"
      ],
      "onClick": () => navigate("/municipal/onboarding/fast"),
      "isActive": false
    }
  ]
  


  return (
    <div className='onboarding_mou' style={{backgroundColor:"#F5F6FA"}}>
      <NavBar/>
        <div className='onboarding_mou__title'>ONBOARDING</div>
        <div className='onboarding_mou__subtitle'>General onboarding portal for the City of [City Name]</div>
        <div className='onboarding_mou__cards'>
            {mouList.map((mou, index) => (
                <ProcessCard key={index} title={mou.title} processes={mou.processes} handleOnClick={mou.onClick} isActive={mou.isActive}/>
            ))}
        </div>
       <Footer/>
    </div>
  )
}

export default OnBoardingMou